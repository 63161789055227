import { Grid } from "@material-ui/core/";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Avatar from "@material-ui/core/Avatar";
import Backdrop from "@material-ui/core/Backdrop";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axios from "axios";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Button, Row, Spinner } from "react-bootstrap";
import Countdown from "react-countdown";
import { useHistory, useParams } from "react-router-dom";
import Web3 from "web3";
import r1 from "../../assets/img/patients/patient.jpg";
import CreateAuctionContract from "../../components/blockchain/Abis/CreateAuctionContract.json";
import WethContract from "../../components/blockchain/Abis/WethContract.json";
import * as Addresses from "../../components/blockchain/Addresses/Addresses";
import HeaderHome from "../../components/Headers/MHeader";
import ConfirmBidModal from "../../components/Modals/ConfirmBidModal";
import LoginErrorModal from "../../components/Modals/LoginErrorModal";
import NetworkErrorModal from "../../components/Modals/NetworkErrorModal";
import WethModal from "../../components/Modals/WethModal";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  badge: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  card: {
    minWidth: 250,
  },
  media1: {
    height: 300,
  },
  media: {
    height: 0,
    paddingTop: "100%", // 16:9
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function AuctionCubeNFTs(props) {
  let history = useHistory();
  let jwt = Cookies.get("Authorization");
  let jwtDecoded;
  console.log(jwtDecoded);
  if (jwt) {
    jwtDecoded = jwtDecode(jwt);
  }
  const [ownerAudio, setOwnerAudio] = useState(new Audio());
  const [nonOwnerAudio, setNonOwnerAudio] = useState(new Audio());
  const [isClaiming, setIsClaiming] = useState(false);
  const [isClaimingWeth] = useState(false);
  const [weth, setWeth] = useState(0);
  const [enableWethButton, setEnableWethButton] = useState(false);
  const [isConfirmingWeth, setIsConfirmingWeth] = useState(false);

  useEffect(() => {
    (async () => {
      ownerAudio.addEventListener("ended", () => ownerAudio.pause());
      nonOwnerAudio.addEventListener("ended", () => nonOwnerAudio.pause());
      return () => {
        ownerAudio.removeEventListener("ended", () => ownerAudio.pause());
        nonOwnerAudio.addEventListener("ended", () => nonOwnerAudio.pause());
      };
    })();
  }, [ownerAudio, nonOwnerAudio]);

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { dropId, cubeId, auctionId } = useParams();
  const [tokenList, setTokenList] = useState([]);
  const [cubeData, setCubeData] = useState({});
  const [auctionData, setAuctionData] = useState({});
  //const [minBid, setMinBid] = useState(0);

  const [bidByUser, setBidByUser] = useState(0);
  const [highestBid, setHighestBid] = useState(0);
  const [bid, setBid] = useState();
  const [balance, setBalance] = useState();
  const [hide, setHide] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [network, setNetwork] = useState("");
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [bidHistory, setBidHistory] = useState([]);
  const [isRemoving, setIsRemoving] = useState(false);
  // if(bidHistory.length!==0)
  // console.log("bidHistory.findIndex(i => i.userId === jwtDecoded.userId)",);
  const [openWeth, setOpenWeth] = useState(false);
  const handleCloseWeth = () => {
    setOpenWeth(false);
  };
  const handleShowWeth = () => {
    setOpenWeth(true);
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleShow = () => {
    setOpen(true);
  };
  const [openNetwork, setOpenNetwork] = useState(false);
  const handleCloseNetwork = () => {
    setOpenNetwork(false);
  };
  const handleShowNetwork = () => {
    setOpenNetwork(true);
  };
  const [openBidModal, setOpenBidModal] = useState(false);
  const handleCloseBidModal = () => {
    setOpenBidModal(false);
  };
  const handleShowBidModal = () => {
    setOpenBidModal(true);
  };
  const [openSpinner, setOpenSpinner] = useState(false);
  const handleCloseSpinner = () => {
    setOpenSpinner(false);
  };
  const handleShowSpinner = () => {
    setOpenSpinner(true);
  };
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  const handleShowBackdrop = () => {
    setOpenBackdrop(true);
  };
  let loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  };
  let Bid = async (e) => {
    e.preventDefault();

    let jwt = Cookies.get("Authorization");
    if (jwt) {
      //   console.log(jwtDecode(jwt));
      await loadWeb3();
      const web3 = window.web3;
      const accounts = await web3.eth.getAccounts();
      const balance = await web3.eth.getBalance(accounts[0]);
      console.log("balance", (balance / 10 ** 18).toString());
      const chainId = await web3.eth.net.getId();
      const network = await web3.eth.net.getNetworkType();
      if (chainId !== 137) {
        setNetwork(network);
        handleShowNetwork();
      } else {
        handleShowBidModal();
      }
    } else {
      handleShow();
    }
  };
  let claimFunds = async (e) => {
    e.preventDefault();

    setIsClaiming(true);
    await loadWeb3();
    const web3 = window.web3;
    const accounts = await web3.eth.getAccounts();
    const chainId = await web3.eth.net.getId();
    const network = await web3.eth.net.getNetworkType();
    if (chainId !== 137) {
      setNetwork(network);
      setIsClaiming(false);
      handleShowNetwork();
    } else {
      handleShowBackdrop();
      const address = Addresses.AuctionAddress;
      const abi = CreateAuctionContract;
      var myContractInstance = await new web3.eth.Contract(abi, address);
      console.log("myContractInstance", myContractInstance);
      console.log(
        "dropData.auctionId, cubeData.tokenId",
        auctionData.auctionId,
        cubeData.tokenId
      );
      let receipt = await myContractInstance.methods
        .claimFunds(auctionData.auctionId)
        .send({ from: accounts[0] }, (err, response) => {
          console.log("get transaction", err, response);
          if (err !== null) {
            console.log("err", err);
            let variant = "error";
            enqueueSnackbar("User Canceled Transaction", { variant });
            handleCloseBackdrop();
            setIsClaiming(false);
          }
        });
      console.log("receipt", receipt);
      let Data = {
        address: accounts[0],
        auctionId: auctionId,
        tokenId: cubeId,
      };
      axios.defaults.headers.common["Authorization"] = `Bearer ${Cookies.get(
        "Authorization"
      )}`;
      axios.post("api/userclaimfunds/claimFunds", Data).then(
        (response) => {
          console.log("response", response);
          setIsClaiming(false);
          handleCloseBackdrop();
          getAuctionCubeNFTs();
          let variant = "success";
          enqueueSnackbar("Funds transferred Successfully.", { variant });
        },
        (error) => {
          if (process.env.NODE_ENV === "development") {
            console.log(error);
            console.log(error.response);
          }
          setIsClaiming(false);
          handleCloseBackdrop();

          let variant = "error";
          enqueueSnackbar("Unable to transfer Funds.", { variant });
        }
      );
    }
  };
  let removeFromAuction = () => {
    setIsRemoving(true);
    let saleData = {
      auctionId: auctionId,
      tokenId: cubeId,
    };
    console.log("saleData", saleData);
    axios.delete("api/auction/deleteauction", {
      data: {
        auctionId: auctionId,
        tokenId: cubeId,
      }
    }).then(
      (response) => {
        console.log("response", response);
        setIsRemoving(false);

        // getAuctionCubeNFTs();
        let variant = "success";
        enqueueSnackbar("Removed from Auction Successfully.", { variant });
        history.push("/");
      },
      (error) => {
        if (process.env.NODE_ENV === "development") {
          console.log(error);
          console.log(error.response);
        }
        setIsRemoving(false);
        let variant = "error";
        enqueueSnackbar("Unable to Remove from Sale.", { variant });
      }
    );
  };

  let claimCube = async (e) => {
    if (cubeData.userClaimFunds) {
      e.preventDefault();
      setIsClaiming(true);
      await loadWeb3();
      const web3 = window.web3;
      const accounts = await web3.eth.getAccounts();
      const chainId = await web3.eth.net.getId();
      const network = await web3.eth.net.getNetworkType();
      if (chainId !== 137) {
        setNetwork(network);
        setIsClaiming(false);
        handleShowNetwork();
      } else {
        handleShowBackdrop();

        const address = Addresses.AuctionAddress;
        const abi = CreateAuctionContract;
        var myContractInstance = await new web3.eth.Contract(abi, address);
        console.log("myContractInstance", myContractInstance);
        console.log(
          "dropData.auctionId, cubeData.tokenId",
          auctionData.auctionId,
          cubeData.tokenId
        );
        let receipt = await myContractInstance.methods
          .claimNFT(auctionData.auctionId)
          .send({ from: accounts[0] }, (err, response) => {
            console.log("get transaction", err, response);
            if (err !== null) {
              console.log("err", err);
              let variant = "error";
              enqueueSnackbar("User Canceled Transaction", { variant });
              handleCloseBackdrop();
              setIsClaiming(false);
            }
          });
        console.log("receipt", receipt);
        let BuyData = {
          auctionId: auctionId,
          tokenId: cubeId,
        };
        console.log("BidData", BuyData);
        axios.post("api/tokens/buyUserToken", BuyData).then(
          (response) => {
            console.log("response", response);
            setIsClaiming(false);
            handleCloseBackdrop();
            getAuctionCubeNFTs();
            let variant = "success";
            enqueueSnackbar("Cube transferred Successfully.", { variant });
          },
          (error) => {
            if (process.env.NODE_ENV === "development") {
              console.log(error);
              console.log(error.response);
            }
            setIsClaiming(false);
            handleCloseBackdrop();

            let variant = "error";
            enqueueSnackbar("Unable to transfer Cube.", { variant });
          }
        );
        let ClaimData = {
          auctionId: auctionId,
          tokenId: cubeId,
          address: accounts[0],
          claimFunds: true,
          claimNft: true,
          withdraw: true,
        };
        axios.put("api/userCubeHistory/claimhistory", ClaimData).then(
          (response) => {
            console.log("response", response);
            setIsClaiming(false);
            handleCloseBackdrop();
            getAuctionCubeNFTs();
          },
          (error) => {
            if (process.env.NODE_ENV === "development") {
              console.log(error);
              console.log(error.response);
            }
            setIsClaiming(false);
            handleCloseBackdrop();

            let variant = "error";
            enqueueSnackbar("Unable to transfer Cube.", { variant });
          }
        );
        let TrasactionData = {
          tokenId: cubeData.tokenId,
          from: cubeData.address,
          to: accounts[0],
          transaction: receipt.transactionHash,
        };

        axios.post("api/transactions/tokenTransaction", TrasactionData).then(
          (response) => {
            console.log("response", response);
            setIsSaving(false);
          },
          (error) => {
            if (process.env.NODE_ENV === "development") {
              console.log(error);
              console.log(error.response);
            }
            setIsSaving(false);
          }
        );
      }
    } else {
      setIsClaiming(false);
      handleCloseBackdrop();

      let variant = "info";
      enqueueSnackbar("Please wait for Owner to claim Funds first.", {
        variant,
      });
    }
  };
  let withdraw = async (e) => {
    e.preventDefault();
    setIsClaiming(true);
    await loadWeb3();
    const web3 = window.web3;
    const accounts = await web3.eth.getAccounts();
    const chainId = await web3.eth.net.getId();
    const network = await web3.eth.net.getNetworkType();
    if (chainId !== 137) {
      setNetwork(network);
      setIsClaiming(false);
      handleShowNetwork();
    } else {
      handleShowBackdrop();
      const address = Addresses.AuctionAddress;
      const abi = CreateAuctionContract;
      var myContractInstance = await new web3.eth.Contract(abi, address);
      console.log("myContractInstance", myContractInstance);
      console.log(
        "dropData.auctionId, cubeData.tokenId",
        auctionData.auctionId,
        cubeData.tokenId
      );
      let receipt = await myContractInstance.methods
        .withdraw(auctionData.auctionId, cubeData.tokenId)
        .send({ from: accounts[0] }, (err, response) => {
          console.log("get transaction", err, response);
          if (err !== null) {
            console.log("err", err);
            let variant = "error";
            enqueueSnackbar("User Canceled Transaction", { variant });
            handleCloseBackdrop();
            setIsClaiming(false);
          }
        });
      console.log("receipt", receipt);
      let ClaimData = {
        auctionId: auctionId,
        tokenId: cubeId,
        address: accounts[0],
        claimFunds: true,
        claimNft: true,
        withdraw: true,
      };
      axios.put("api/userCubeHistory/claimhistory", ClaimData).then(
        (response) => {
          console.log("response", response);
          setIsClaiming(false);
          handleCloseBackdrop();
          let variant = "success";
          enqueueSnackbar("Funds withdrawn Successfully", { variant });
          getAuctionCubeNFTs();
        },
        (error) => {
          if (process.env.NODE_ENV === "development") {
            console.log(error);
            console.log(error.response);
          }
          let variant = "success";
          enqueueSnackbar("Unable to withdraw funds", { variant });
          setIsClaiming(false);
          handleCloseBackdrop();
        }
      );
    }
  };
  let ConfirmBidding = async () => {
    handleCloseBidModal();
    setIsSaving(true);
    console.log("bid", bid);
    if (bid < (highestBid - bidByUser + auctionData.bidDelta) / 10 ** 18) {
      let variant = "error";
      enqueueSnackbar("Bid Must be Greater than Minimum Bid", { variant });
      handleCloseBackdrop();
      setIsSaving(false);
    } else {
      await loadWeb3();
      const web3 = window.web3;
      const accounts = await web3.eth.getAccounts();
      const chainId = await web3.eth.net.getId();
      const network = await web3.eth.net.getNetworkType();
      if (chainId !== 137) {
        setNetwork(network);
        setIsSaving(false);
        handleShowNetwork();
      } else {
        handleShowBackdrop();
        const wethAddress = Addresses.WethAddress;
        const wethAbi = WethContract;
        // const address = Addresses.AuctionAddress;
        var myWethContractInstance = await new web3.eth.Contract(
          wethAbi,
          wethAddress
        );
        let wethReceipt = await myWethContractInstance.methods
          .balanceOf(accounts[0])
          .call();
        console.log("wethReceipt", wethReceipt);

        if (wethReceipt < bid * 10 ** 18) {
          let variant = "error";
          enqueueSnackbar("You have insufficient Weth", { variant });
          setEnableWethButton(true);
          setIsSaving(false);

          handleCloseBackdrop();
        } else {
          setEnableWethButton(false);
          const address = Addresses.AuctionAddress;
          const abi = CreateAuctionContract;
          await myWethContractInstance.methods
            .approve(address, (bid * 10 ** 18).toString())
            .send({ from: accounts[0] }, (err, response) => {
              console.log("get transaction", err, response);
              if (err !== null) {
                console.log("err", err);
                let variant = "error";
                enqueueSnackbar("User Canceled Transaction", { variant });
                handleCloseBackdrop();
                setIsClaiming(false);
              }
            });

          var myContractInstance = await new web3.eth.Contract(abi, address);
          console.log("myContractInstance", myContractInstance);
          console.log("accounts[0]", accounts[0]);
          console.log(
            "dropData.auctionId, cubeData.tokenId",
            auctionData.auctionId,
            cubeData.tokenId
          );
          let receipt = await myContractInstance.methods
            .bid(
              auctionData.auctionId,
              (bid * 10 ** 18).toString()
            )
            .send({ from: accounts[0] }, (err, response) => {
              console.log("get transaction", err, response);
              if (err !== null) {
                console.log("err", err);
                let variant = "error";
                enqueueSnackbar("User Canceled Transaction", { variant });
                handleCloseBackdrop();
                setIsSaving(false);
              }
            });
          console.log("receipt", receipt);
          let BidData = {
            auctionId: auctionId,
            tokenId: cubeId,
            bid: bid * 10 ** 18,
            address: accounts[0],
          };
          console.log("BidData", BidData);
          axios.post("api/userCubeHistory/createHistory", BidData).then(
            (response) => {
              console.log("response", response);
              setIsSaving(false);
              handleCloseBackdrop();
              getAuctionCubeNFTs();
              let variant = "success";
              enqueueSnackbar("Bid Created Successfully.", { variant });
            },
            (error) => {
              if (process.env.NODE_ENV === "development") {
                console.log(error);
                console.log(error.response);
              }
              setIsSaving(false);
              handleCloseBackdrop();

              let variant = "error";
              enqueueSnackbar("Unable to Create Bid.", { variant });
            }
          );
        }
      }
    }
  };
  let getAuctionCubeNFTs = () => {
    handleShowSpinner();

    let Data = {
      tokenId: cubeId,
      check: "userAuction",
      auctionId: auctionId,
    };
    console.log("Data", Data);
    let bidData = {
      auctionId: auctionId,
      tokenId: cubeId,
    };
    axios.post(`api/userCubeHistory/history`, bidData).then(
      (res) => {
        console.log("res", res);
        if (res.data.success) {
          setBidHistory(res.data.userCubeHistory);
          // console.log("jwtDecoded.userId", jwtDecoded.userId);
          // for (let i = 0; i < res.data.Dropcubeshistorydata.length; i++) {
          //     console.log("res.data.Dropcubeshistorydata", res.data.Dropcubeshistorydata[i].userId);
          // }
          // let index = res.data.Dropcubeshistorydata.findIndex(i => i.userId === jwtDecoded.userId);
          // console.log(index, "index");
        }
        handleCloseSpinner();
      },
      (error) => {
        if (process.env.NODE_ENV === "development") {
          console.log(error);
          console.log(error.response);
        }
        handleCloseSpinner();
      }
    );
    axios.post("api/tokens/SingleTokenId", Data).then(
      async (response) => {
        console.log("response", response);
        setTokenList(response.data.nftData);
        setCubeData(response.data.tokenData);
        // setOwnerAudio(response.data.tokensdata.ownermusicfile)
        setOwnerAudio(new Audio(response.data.tokenData.ownerMusicFile));
        setNonOwnerAudio(new Audio(response.data.tokenData.nonOwnerMusicFile));
        setAuctionData(response.data.userAuctionData);

        await loadWeb3();
        const web3 = window.web3;
        const accounts = await web3.eth.getAccounts();
        const address = Addresses.AuctionAddress;
        const abi = CreateAuctionContract;
        var myContractInstance = await new web3.eth.Contract(abi, address);
        console.log("cubeId", response.data.tokenData.tokenId);
        console.log("accounts[0]", accounts[0]);
        if (response.data.userAuctionData !== null) {
          let highestBid = await myContractInstance.methods
            .getCurrentBid(response.data.userAuctionData.auctionId)
            .call();
          console.log("highestBid", highestBid);
          setHighestBid(highestBid);
          let bidByUser = await myContractInstance.methods
            .bidByUser(response.data.userAuctionData.auctionId, accounts[0])
            .call();
          console.log("bidByUser", bidByUser);
          setBidByUser(bidByUser);
          if (highestBid === "0") {
            setBid(response.data.userAuctionData.minimumBid / 10 ** 18);
          } else {
            setBid(
              (highestBid - bidByUser + response.data.userAuctionData.bidDelta) /
              10 ** 18
            );
          }
        }
        // setBid((response.data.UserAuctiondata.minimumBid) / 10 ** 18)

        axios
          .get(
            `/api/transactions/tokenTransaction/${response.data.tokenData.tokenId}`
          )
          .then(
            (res) => {
              console.log("res", res);
              if (res.data.success)
                setTransactionHistory(res.data.data);
              handleCloseSpinner();
            },
            (error) => {
              if (process.env.NODE_ENV === "development") {
                console.log(error);
                console.log(error.response);
              }
              if (error.response.data !== undefined) {
                if (
                  error.response.data ===
                  "Unauthorized access (invalid token) !!"
                ) {
                  localStorage.removeItem("Address");
                  Cookies.remove("Authorization");
                  window.location.reload();
                }
              }
              handleCloseSpinner();
            }
          );
      },
      (error) => {
        if (process.env.NODE_ENV === "development") {
          console.log(error);
          console.log(error.response);
        }
        handleCloseSpinner();
      }
    );
  };
  useEffect(() => {
    (async () => {
      getAuctionCubeNFTs();
      await loadWeb3();
      const web3 = window.web3;
      const accounts = await web3.eth.getAccounts();
      const balance = await web3.eth.getBalance(accounts[0]);

      console.log("balance", (balance / 10 ** 18).toString());
      setBalance(balance);
    })();
  }, []);
  let getWeth = () => {
    // console.log("GET");
    handleShowWeth();
  };
  let confirmGetWeth = async () => {
    await loadWeb3();
    handleShowBackdrop();
    setIsConfirmingWeth(true);
    const web3 = window.web3;
    const wethAddress = Addresses.WethAddress;
    const wethAbi = WethContract;
    //const address = Addresses.AuctionAddress;
    const accounts = await web3.eth.getAccounts();
    var myWethContractInstance = await new web3.eth.Contract(
      wethAbi,
      wethAddress
    );
    let wethReceipt = await myWethContractInstance.methods
      .deposit()
      .send({ from: accounts[0], value: weth * 10 ** 18 }, (err, response) => {
        console.log("get transaction", err, response);
        if (err !== null) {
          console.log("err", err);
          let variant = "error";
          enqueueSnackbar("User Canceled Transaction", { variant });
          handleCloseBackdrop();
          setIsConfirmingWeth(false);
        }
      });
    handleCloseBackdrop();
    handleCloseWeth();

    setIsConfirmingWeth(false);
    console.log("wethReceipt", wethReceipt);
    let variant = "Success";
    enqueueSnackbar("Successfully transferred Weth to your account", {
      variant,
    });
  };
  return (
    <div className="main-wrapper">
      <div className="home-section home-full-height">
        <HeaderHome selectedNav={"Market"} />
        <div className="card">
          <div className="card-body" style={{ marginTop: "110px" }}>
            {openSpinner ? (
              <div align="center" className="text-center">
                <Spinner
                  animation="border"
                  role="status"
                  style={{ color: " #9f55ff" }}
                ></Spinner>
                <span style={{ color: " #9f55ff" }} className="sr-only">
                  Loading...
                </span>
              </div>
            ) : (auctionData !== null ? (<form>
              <section className="section">
                <div className="container-fluid">
                  <div className="" style={{ paddingTop: "0px" }}>
                    <div className="row">
                      <div className="col-md-12 col-lg-6">
                        <Card className={classes.root}>
                          <CardActionArea>
                            <CardMedia
                              className={classes.media1}
                              title=""
                              image=""
                            >
                              {console.log("test hide", hide)}
                              {hide === true ? (
                                <div className="wrapper">
                                  <div className="cube-box" style={{ marginBottom: "100px", marginTop: "80px !important" }}>
                                    {tokenList.map((j, jindex) => (
                                      <img
                                        src={j[0].artwork}
                                        key={jindex}
                                        style={{
                                          border:
                                            j[0].type === "Mastercraft"
                                              ? "4px solid  #9f55ff"
                                              : j[0].type === "Legendary"
                                                ? "4px solid #FFD700"
                                                : j[0].type === "Epic"
                                                  ? "4px solid #9400D3"
                                                  : j[0].type === "Rare"
                                                    ? "4px solid #0000FF"
                                                    : j[0].type === "Uncommon"
                                                      ? "4px solid #008000"
                                                      : j[0].type === "Common"
                                                        ? "4px solid #FFFFFF"
                                                        : "none",
                                        }}
                                        alt=""
                                      />
                                    ))}
                                    {new Array(6 - tokenList.length)
                                      .fill(0)
                                      .map((_, index) => (
                                        <img src={r1} alt="" />
                                      ))}
                                  </div>
                                </div>
                              ) : (
                                <div className="mainDiv" style={{ marginBottom: "100px", marginTop: "80px !important" }}>
                                  {jwt ? (
                                    cubeData.userId === jwtDecoded.id ? (
                                      <span
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setHide(true);
                                          // ownerAudio.crossOrigin = 'anonymous';
                                          ownerAudio.setAttribute(
                                            "crossorigin",
                                            "anonymous"
                                          );
                                          ownerAudio.play();
                                        }}
                                      >
                                        <div className="wrapper">
                                          <div className="cube-box" style={{ marginBottom: "100px", marginTop: "80px !important" }}>
                                            {tokenList.map((j, jindex) => (
                                              <img
                                                src={j[0].artwork}
                                                key={jindex}
                                                style={{
                                                  border:
                                                    j[0].type === "Mastercraft"
                                                      ? "4px solid  #9f55ff"
                                                      : j[0].type === "Legendary"
                                                        ? "4px solid #FFD700"
                                                        : j[0].type === "Epic"
                                                          ? "4px solid #9400D3"
                                                          : j[0].type === "Rare"
                                                            ? "4px solid #0000FF"
                                                            : j[0].type === "Uncommon"
                                                              ? "4px solid #008000"
                                                              : j[0].type === "Common"
                                                                ? "4px solid #FFFFFF"
                                                                : "none",
                                                }}
                                                alt=""
                                              />
                                            ))}
                                            {new Array(6 - tokenList.length)
                                              .fill(0)
                                              .map((_, index) => (
                                                <img src={r1} alt="" />
                                              ))}
                                          </div>
                                        </div>
                                      </span>
                                    ) : (
                                      <span
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setHide(true);
                                          // nonOwnerAudio.crossOrigin = 'anonymous';
                                          nonOwnerAudio.setAttribute(
                                            "crossorigin",
                                            "anonymous"
                                          );
                                          nonOwnerAudio.play();
                                          setTimeout(() => {
                                            setHide(false);
                                            nonOwnerAudio.pause();
                                          }, 3000);
                                        }}
                                      >
                                        <div className="wrapper" >
                                          <div className="cube-box" style={{ marginBottom: "100px", marginTop: "80px !important" }}>
                                            {tokenList.map((j, jindex) => (
                                              <img
                                                src={j[0].artwork}
                                                key={jindex}
                                                style={{
                                                  border:
                                                    j[0].type === "Mastercraft"
                                                      ? "4px solid  #9f55ff"
                                                      : j[0].type === "Legendary"
                                                        ? "4px solid #FFD700"
                                                        : j[0].type === "Epic"
                                                          ? "4px solid #9400D3"
                                                          : j[0].type === "Rare"
                                                            ? "4px solid #0000FF"
                                                            : j[0].type === "Uncommon"
                                                              ? "4px solid #008000"
                                                              : j[0].type === "Common"
                                                                ? "4px solid #FFFFFF"
                                                                : "none",
                                                }}
                                                alt=""
                                              />
                                            ))}
                                            {new Array(6 - tokenList.length)
                                              .fill(0)
                                              .map((_, index) => (
                                                <img src={r1} alt="" />
                                              ))}
                                          </div>
                                        </div>
                                      </span>
                                    )
                                  ) : (
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                      component="p"
                                    >
                                      <strong>LOGIN TO GET ACCESS </strong>
                                    </Typography>
                                  )}
                                </div>
                              )}
                            </CardMedia>
                          </CardActionArea>
                        </Card>
                      </div>

                      {dropId !== "notdrop" ? (
                        <div className="col-md-12 col-lg-6">
                          {/* {console.log()} */}
                          {enableWethButton ? (
                            isClaimingWeth ? (
                              <div align="center" className="text-center">
                                <Spinner
                                  animation="border"
                                  role="status"
                                  style={{ color: " #9f55ff" }}
                                ></Spinner>
                                <span
                                  style={{ color: " #9f55ff" }}
                                  className="sr-only"
                                >
                                  Loading...
                                </span>
                              </div>
                            ) : (
                              <Button
                                variant="primary"
                                onClick={(e) => getWeth(e)}
                                style={{
                                  backgroundColor: "#9f55ff",
                                  border: "none",
                                  float: "right",
                                }}
                              >
                                Get More WMATIC
                              </Button>
                            )
                          ) : null}
                          {new Date() >
                            new Date(auctionData.auctionEndsAt) ? (
                            jwt ? (
                              <>
                                {cubeData.userId === jwtDecoded.id &&
                                  bidHistory.length === 0 ? (
                                  isRemoving ? (
                                    <div
                                      align="center"
                                      className="text-center"
                                    >
                                      <Spinner
                                        animation="border"
                                        role="status"
                                        style={{ color: " #9f55ff" }}
                                      ></Spinner>
                                      <span
                                        style={{ color: " #9f55ff" }}
                                        className="sr-only"
                                      >
                                        Loading...
                                      </span>
                                    </div>
                                  ) : (
                                    <Button
                                      variant="primary"
                                      style={{
                                        backgroundColor: "#9f55ff",
                                        border: "none",
                                        float: "right",
                                      }}
                                      onClick={removeFromAuction}
                                    >
                                      Remove from Auction
                                    </Button>
                                  )
                                ) : null}
                              </>
                            ) : null
                          ) : null}
                          {new Date() >
                            new Date(auctionData.auctionEndsAt) ? (
                            jwt ? (
                              <>
                                {cubeData.userId === jwtDecoded.id &&
                                  bidHistory.length !== 0 ? (
                                  isClaiming ? (
                                    <div
                                      align="center"
                                      className="text-center"
                                    >
                                      <Spinner
                                        animation="border"
                                        role="status"
                                        style={{ color: " #9f55ff" }}
                                      ></Spinner>
                                      <span
                                        style={{ color: " #9f55ff" }}
                                        className="sr-only"
                                      >
                                        Loading...
                                      </span>
                                    </div>
                                  ) : cubeData.userClaimFunds ? (
                                    <Button
                                      variant="primary"
                                      disabled
                                      style={{
                                        backgroundColor: "#9f55ff",
                                        border: "none",
                                        float: "right",
                                      }}
                                    >
                                      Claim Funds
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="primary"
                                      onClick={(e) => claimFunds(e)}
                                      style={{
                                        backgroundColor: "#9f55ff",
                                        border: "none",
                                        float: "right",
                                      }}
                                    >
                                      Claim Funds
                                    </Button>
                                  )
                                ) : bidHistory.length !== 0 ? (
                                  bidHistory[bidHistory.length - 1].userId ===
                                    jwtDecoded.id ? (
                                    isClaiming ? (
                                      <div
                                        align="center"
                                        className="text-center"
                                      >
                                        <Spinner
                                          animation="border"
                                          role="status"
                                          style={{ color: " #9f55ff" }}
                                        ></Spinner>
                                        <span
                                          style={{ color: " #9f55ff" }}
                                          className="sr-only"
                                        >
                                          Loading...
                                        </span>
                                      </div>
                                    ) : bidHistory[bidHistory.length - 1]
                                      .claimNft ? (
                                      <Button
                                        variant="primary"
                                        disabled
                                        style={{
                                          backgroundColor: "#9f55ff",
                                          border: "none",
                                          float: "right",
                                        }}
                                      >
                                        Claim Cube
                                      </Button>
                                    ) : (
                                      <Button
                                        variant="primary"
                                        onClick={(e) => claimCube(e)}
                                        style={{
                                          backgroundColor: "#9f55ff",
                                          border: "none",
                                          float: "right",
                                        }}
                                      >
                                        Claim Cube
                                      </Button>
                                    )
                                  ) : bidHistory.findIndex(
                                    (i) => i.userId === jwtDecoded.id
                                  ) !== -1 ? (
                                    isClaiming ? (
                                      <div
                                        align="center"
                                        className="text-center"
                                      >
                                        <Spinner
                                          animation="border"
                                          role="status"
                                          style={{ color: " #9f55ff" }}
                                        ></Spinner>
                                        <span
                                          style={{ color: " #9f55ff" }}
                                          className="sr-only"
                                        >
                                          Loading...
                                        </span>
                                      </div>
                                    ) : bidHistory[
                                      bidHistory.findIndex(
                                        (i) =>
                                          i.userId === jwtDecoded.id
                                      )
                                    ].withdraw ? (
                                      <Button
                                        variant="primary"
                                        disabled
                                        style={{
                                          backgroundColor: "#9f55ff",
                                          border: "none",
                                          float: "right",
                                        }}
                                      >
                                        Your bid has been refunded.
                                      </Button>
                                    ) : (
                                      <Button
                                        variant="primary"
                                        disabled
                                        onClick={(e) => withdraw(e)}
                                        style={{
                                          backgroundColor: "#9f55ff",
                                          border: "none",
                                          float: "right",
                                        }}
                                      >
                                        Your bid has been refunded.
                                      </Button>
                                    )
                                  ) : null
                                ) : null}
                              </>
                            ) : null
                          ) : null}
                          <Typography
                            variant="h4"
                            gutterBottom
                            style={{ color: "#9f55ff" }}
                          >
                            {cubeData.title}
                          </Typography>
                          {highestBid !== "0" ? (
                            <Typography
                              variant="h5"
                              gutterBottom
                              style={{ color: "#9f55ff" }}
                            >
                              Highest Bid : {highestBid / 10 ** 18} WMATIC{" "}
                            </Typography>
                          ) : null}
                          <Typography
                            variant="h5"
                            gutterBottom
                            style={{ color: "#9f55ff" }}
                          >
                            Current Bid : {auctionData.minimumBid / 10 ** 18}{" "}
                            WMATIC{" "}
                          </Typography>
                          {/* <Typography variant="h5" gutterBottom>Current Bid : {(highestBid - bidByUser) / 10 ** 18} WMATIC </Typography> */}
                          <Typography
                            variant="h5"
                            gutterBottom
                            style={{ color: "#9f55ff" }}
                          >
                            Bid Delta : {auctionData.bidDelta / 10 ** 18} WMATIC{" "}
                          </Typography>
                          <Typography
                            variant="h5"
                            gutterBottom
                            style={{ color: "#9f55ff" }}
                          >
                            your bid : {bidByUser / 10 ** 18} WMATIC{" "}
                          </Typography>
                          {new Date() <
                            new Date(auctionData.auctionStartsAt) ? (
                            <Typography
                              variant="h5"
                              gutterBottom
                              color="textSecondary"
                            >
                              <strong>Auction Starts At:</strong>
                              <span style={{ color: "#00FF00" }}>
                                <Countdown
                                  daysInHours
                                  date={new Date(auctionData.auctionStartsAt)}
                                ></Countdown>
                              </span>
                            </Typography>
                          ) : new Date() >
                            new Date(auctionData.auctionStartsAt) &&
                            new Date() <
                            new Date(auctionData.auctionEndsAt) ? (
                            <Typography
                              variant="h5"
                              gutterBottom
                              color="textSecondary"
                              component="p"
                            >
                              <strong>Auction Ends At: </strong>
                              <span style={{ color: " #9f55ff" }}>
                                <Countdown
                                  daysInHours
                                  date={new Date(auctionData.auctionEndsAt)}
                                ></Countdown>
                              </span>
                            </Typography>
                          ) : (
                            <Typography
                              variant="h5"
                              gutterBottom
                              style={{ color: " #9f55ff" }}
                              component="p"
                            >
                              <strong>Auction Ended</strong>
                            </Typography>
                          )}
                          <h3 className="text-muted">Music Artist</h3>

                          <CardHeader
                            avatar={
                              <Avatar
                                src={cubeData.musicArtistProfile}
                                aria-label="Artist"
                                className={classes.avatar}
                              />
                            }
                            title={cubeData.musicArtistName}
                            subheader={cubeData.musicArtistAbout}
                          />
                          <Row>
                            {new Date() <
                              new Date(auctionData.auctionStartsAt) ? (
                              <>
                                <label> Enter Bid: (WMATIC)</label>
                                <input
                                  type="number"
                                  step="0.0001"
                                  diabled
                                  min={auctionData.minimumBid / 10 ** 18}
                                  max={balance / 10 ** 18}
                                  className="form-control"
                                  style={{ marginBottom: "20px" }}
                                  value={bid}
                                  onChange={(evt) => { }}
                                />
                                <br></br>
                                <Button
                                  variant="primary"
                                  block
                                  disabled
                                  style={{
                                    backgroundColor: "#9f55ff",
                                    border: "none",
                                  }}
                                >
                                  Place a bid
                                </Button>
                              </>
                            ) : new Date() >
                              new Date(auctionData.auctionStartsAt) &&
                              new Date() <
                              new Date(auctionData.auctionEndsAt) ? (
                              <>
                                <label> Enter Bid:(WMATIC) </label>
                                {jwt ? (
                                  cubeData.userId === jwtDecoded.id ? (
                                    <>
                                      <input
                                        type="number"
                                        step="0.0001"
                                        disabled
                                        className="form-control"
                                        style={{ marginBottom: "20px" }}
                                        value={bid}
                                      />
                                      <br></br>
                                      <Button
                                        variant="primary"
                                        block
                                        disabled
                                        style={{
                                          backgroundColor: "#9f55ff",
                                          border: "none",
                                        }}
                                      >
                                        You Cannot bid on your own Cube
                                      </Button>
                                    </>
                                  ) : auctionData.minimumBid / 10 ** 18 >
                                    balance / 10 ** 18 ? (
                                    <>
                                      <input
                                        type="number"
                                        step="0.0001"
                                        disabled
                                        className="form-control"
                                        style={{ marginBottom: "20px" }}
                                        value={bid}
                                      />
                                      <br></br>
                                      <Button
                                        variant="primary"
                                        block
                                        disabled
                                        style={{
                                          backgroundColor: "#9f55ff",
                                          border: "none",
                                        }}
                                      >
                                        Insufficient Balance
                                      </Button>
                                    </>
                                  ) : (
                                    <>
                                      {highestBid !== "0" ? (
                                        <input
                                          type="number"
                                          step={
                                            auctionData.bidDelta / 10 ** 18
                                          }
                                          min={
                                            (highestBid -
                                              bidByUser +
                                              auctionData.bidDelta) /
                                            10 ** 18
                                          }
                                          max={balance / 10 ** 18}
                                          className="form-control"
                                          style={{ marginBottom: "20px" }}
                                          value={bid}
                                          onChange={(evt) => {
                                            if (evt.target.value >= 0) {
                                              if (
                                                evt.target.value <
                                                (highestBid -
                                                  bidByUser +
                                                  auctionData.bidDelta) /
                                                10 ** 18
                                              ) {
                                                setBid(
                                                  (highestBid -
                                                    bidByUser +
                                                    auctionData.bidDelta) /
                                                  10 ** 18
                                                );
                                              } else if (
                                                evt.target.value >
                                                balance / 10 ** 18
                                              ) {
                                                setBid(balance / 10 ** 18);
                                              } else {
                                                setBid(evt.target.value);
                                              }
                                            } else {
                                              setBid(
                                                (highestBid -
                                                  bidByUser +
                                                  auctionData.bidDelta) /
                                                10 ** 18
                                              );
                                            }
                                          }}
                                        />
                                      ) : (
                                        <input
                                          type="number"
                                          step={
                                            auctionData.bidDelta / 10 ** 18
                                          }
                                          min={
                                            auctionData.minimumBid / 10 ** 18
                                          }
                                          max={balance / 10 ** 18}
                                          className="form-control"
                                          style={{ marginBottom: "20px" }}
                                          value={bid}
                                          onChange={(evt) => {
                                            if (evt.target.value >= 0) {
                                              if (
                                                evt.target.value <
                                                auctionData.minimumBid /
                                                10 ** 18
                                              ) {
                                                setBid(
                                                  auctionData.minimumBid /
                                                  10 ** 18
                                                );
                                              } else if (
                                                evt.target.value >
                                                balance / 10 ** 18
                                              ) {
                                                setBid(balance / 10 ** 18);
                                              } else {
                                                setBid(evt.target.value);
                                              }
                                            } else {
                                              setBid(
                                                auctionData.minimumBid /
                                                10 ** 18
                                              );
                                            }
                                          }}
                                        />
                                      )}

                                      <br></br>
                                      {isSaving ? (
                                        <div
                                          align="center"
                                          className="text-center"
                                        >
                                          <Spinner
                                            animation="border"
                                            role="status"
                                            style={{ color: " #9f55ff" }}
                                          ></Spinner>
                                          <span
                                            style={{ color: " #9f55ff" }}
                                            className="sr-only"
                                          >
                                            Loading...
                                          </span>
                                        </div>
                                      ) : (
                                        <Button
                                          variant="primary"
                                          block
                                          onClick={(e) => Bid(e)}
                                          style={{
                                            backgroundColor: "#9f55ff",
                                            border: "none",
                                          }}
                                        >
                                          place a Bid
                                        </Button>
                                      )}
                                    </>
                                  )
                                ) : null}
                                {/* {}` */}
                              </>
                            ) : (
                              <Button
                                variant="primary"
                                block
                                disabled
                                style={{
                                  backgroundColor: "#9f55ff",
                                  border: "none",
                                }}
                              >
                                Auction Ended
                              </Button>
                            )}
                          </Row>
                        </div>
                      ) : (
                        <div className="col-md-12 col-lg-6">
                          {/* <Chip clickable style={{ marginTop: '20px' }}
                                                            color="" label="@UserName" />
                                                        <h1> </h1> */}
                          <Typography variant="h4" gutterBottom>
                            {cubeData.title}
                          </Typography>
                          <Typography
                            variant="h5"
                            gutterBottom
                            style={{ color: "#9f55ff" }}
                          >
                            Reserve Price
                          </Typography>
                          <Typography variant="h5" gutterBottom>
                            {cubeData.salePrice / 10 ** 18} WMATIC{" "}
                          </Typography>
                          <h3 className="text-muted">Music Artist</h3>
                          <CardHeader
                            avatar={
                              <Avatar
                                src={cubeData.musicArtistProfile}
                                aria-label="Artist"
                                className={classes.avatar}
                              />
                            }
                            title={cubeData.musicArtistName}
                            subheader={cubeData.musicArtistAbout}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </section>
              <div className="form-group">
                <br></br>

                <div className="row">
                  <div className="col-md-12 col-lg-6">
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justifyContent="flex-start"
                    // alignItems="flex-start"
                    >
                      {/* {console.log("tokenList", tokenList)} */}
                      {hide === true || hide === false
                        ? tokenList.map((i, index) => (
                          <Grid item xs={12} sm={6} md={6} key={index}>
                            <Card
                              style={{ height: "100%" }}
                              variant="outlined"
                            >
                              <CardHeader
                                className="text-center"
                                title={i[0].title}
                              />
                              <CardMedia
                                variant="outlined"
                                style={{
                                  border:
                                    i[0].type === "Mastercraft"
                                      ? "4px solid  #9f55ff"
                                      : i[0].type === "Legendary"
                                        ? "4px solid #FFD700"
                                        : i[0].type === "Mastercraft"
                                          ? "4px solid # #9f55ff"
                                          : i[0].type === "Epic"
                                            ? "4px solid #9400D3"
                                            : i[0].type === "Rare"
                                              ? "4px solid #0000FF"
                                              : i[0].type === "Uncommon"
                                                ? "4px solid #008000"
                                                : i[0].type === "Common"
                                                  ? "4px solid #FFFFFF"
                                                  : "none",
                                }}
                                className={classes.media}
                                image={i[0].artwork}
                                title="NFT Image"
                              />
                              <CardContent>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  component="p"
                                >
                                  <strong>Artwork Description: </strong>
                                  {i[0].description}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  component="p"
                                >
                                  <strong>Token Rarity: </strong>
                                  {i[0].type}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  component="p"
                                >
                                  <strong>Token Supply: </strong>
                                  {i[0].tokenSupply}
                                </Typography>
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  color="textSecondary"
                                  className="text-center"
                                >
                                  Image Artist
                                </Typography>
                                <CardHeader
                                  avatar={
                                    <Avatar
                                      src={i[0].imageArtistProfile}
                                      aria-label="Artist"
                                      className={classes.avatar}
                                    />
                                  }
                                  title={i[0].imageArtistName}
                                  subheader={i[0].imageArtistAbout}
                                />
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  component="p"
                                >
                                  <strong>Website URL: </strong>
                                  {i[0].imageArtistWebsite}
                                </Typography>
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  color="textSecondary"
                                  className="text-center"
                                >
                                  Producer
                                </Typography>
                                <CardHeader
                                  avatar={
                                    <Avatar
                                      src={i[0].producerProfile}
                                      aria-label="Producer"
                                      className={classes.avatar}
                                    />
                                  }
                                  title={i[0].producerName}
                                  subheader={i[0].producerInspiration}
                                />
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  color="textSecondary"
                                  className="text-center"
                                >
                                  Executive Producer
                                </Typography>
                                <CardHeader
                                  avatar={
                                    <Avatar
                                      src={i[0].executiveProducerProfile}
                                      aria-label="Executive Producer"
                                      className={classes.avatar}
                                    />
                                  }
                                  title={i[0].executiveProducerName}
                                  subheader={
                                    i[0].executiveProducerInspiration
                                  }
                                />
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  color="textSecondary"
                                  className="text-center"
                                >
                                  Fan
                                </Typography>
                                <CardHeader
                                  avatar={
                                    <Avatar
                                      src={i[0].fanProfile}
                                      aria-label="Fan"
                                      className={classes.avatar}
                                    />
                                  }
                                  title={i[0].fanName}
                                  subheader={i[0].fanInspiration}
                                />

                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  component="p"
                                >
                                  <strong>Other: </strong>
                                  {i[0].other}
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))
                        : null}
                    </Grid>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <div className="form-group">
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography
                            variant="h6"
                            gutterBottom
                            style={{ color: "#9f55ff" }}
                          >
                            Tx History
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {transactionHistory.length === 0 ? (
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                            >
                              <strong>No Transaction History Found </strong>
                            </Typography>
                          ) : null}
                          <Grid
                            container
                            spacing={2}
                            direction="row"
                            justifyContent="flex-start"
                          // alignItems="flex-start"
                          >
                            {transactionHistory
                              .slice(0)
                              .reverse()
                              .map((i, index) => (
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  key={index}
                                >
                                  <Card className={classes.root}>
                                    <CardActionArea style={{ margin: "5px" }}>
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                      >
                                        <strong>From : </strong>
                                        {i.from}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                      >
                                        <strong>To : </strong>
                                        {i.to}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                      >
                                        <strong>Hash : </strong>
                                        <a
                                          href={
                                            "https://private.etherscan.io/tx/" +
                                            i.transaction
                                          }
                                          target="_blank"
                                          style={{ color: "#9f55ff" }}
                                          rel="noopener"
                                        >
                                          <span style={{ cursor: "pointer" }}>
                                            {i.transaction.substr(0, 20)}. . .
                                          </span>
                                        </a>
                                      </Typography>
                                    </CardActionArea>
                                  </Card>
                                </Grid>
                              ))}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography
                            variant="h6"
                            gutterBottom
                            style={{ color: "#9f55ff" }}
                          >
                            Bidding History
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {bidHistory.length === 0 ? (
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                            >
                              <strong>No Bidding History Found </strong>
                            </Typography>
                          ) : null}
                          <Grid
                            container
                            spacing={2}
                            direction="row"
                            justifyContent="flex-start"
                          >
                            {bidHistory
                              .slice(0)
                              .reverse()
                              .map((i, index) => (
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  key={index}
                                >
                                  <Card className={classes.root}>
                                    <CardActionArea style={{ margin: "5px" }}>
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                      >
                                        <strong>Address : </strong>
                                        {i.address}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                      >
                                        <strong>Bid : </strong>
                                        <span
                                          style={{
                                            cursor: "pointer",
                                            color: "#9f55ff",
                                          }}
                                        >
                                          {i.bid / 10 ** 18} WMATIC
                                        </span>
                                      </Typography>
                                    </CardActionArea>
                                  </Card>
                                </Grid>
                              ))}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            </form>) : ('Your cube has been transferred')

            )}
          </div>
        </div>
      </div>
      <LoginErrorModal show={open} handleClose={handleClose} />
      <ConfirmBidModal
        bid={bid}
        balance={balance}
        minimumBid={auctionData !== null ? auctionData.minimumBid : 'N/A'}
        bidDelta={auctionData !== null ? auctionData.bidDelta : ''}
        show={openBidModal}
        handleClose={handleCloseBidModal}
        ConfirmBidding={ConfirmBidding}
      />
      <WethModal
        isConfirmingWeth={isConfirmingWeth}
        weth={weth}
        balance={balance}
        setWeth={setWeth}
        show={openWeth}
        handleClose={handleCloseWeth}
        confirmGetWeth={confirmGetWeth}
      />
      <NetworkErrorModal
        show={openNetwork}
        handleClose={handleCloseNetwork}
        network={network}
      />
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default AuctionCubeNFTs;
