import styled from "styled-components";

export const HomeBannerContainer = styled.section`
  height: 550px;
  padding-top: 90px;
  background-image: linear-gradient(
    to right,
    rgb(136, 78, 211),
    rgb(16, 20, 128)
  );
`;

export const HomeBannerButtonContainer = styled.div`
  width: 350px;
  height: 150px;
  display: grid;
  grid-template-rows: repeat(2, 60px);
  grid-template-columns: repeat(2, 150px);
  column-gap: 40px;
  justify-content: center;
  align-items: center;
`;
