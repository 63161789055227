import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";

import AuctionCard from "../../components/Cards/AuctionCard";
import { DropsCardContainer } from "./Market.styles";

/*const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "100%", // 16:9
  },
  badge: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));*/

function Drops() {
  //const [hide, setHide] = useState(false);
  const [tokenList, setTokenList] = useState([]);
  const [imageData, setImageData] = useState([]);

  const [rowsPerPage] = React.useState(10);
  const [totalDrops, setTotalDrops] = React.useState(0);
  //const [page, setPage] = React.useState(0);
  const [open] = React.useState(false);
  //const classes = useStyles();
  let getMyDrops = (start, end) => {
    axios.get(`api/drops/${start}/${end}`).then(
      (response) => {
        console.log("response", response);
        setTokenList(response.data.drops);
        setTotalDrops(response.data.dropCount);
      },
      (error) => {
        if (process.env.NODE_ENV === "development") {
          console.log(error);
          console.log(error.response);
        }
      }
    );
  };
  useEffect(() => {
    getMyDrops(0, rowsPerPage);
  }, []);
  return (
    <>
      <div className="container-fluid">
        {/* <!-- Page Header --> */}
        <div className="page-header">
          {/* <Container> */}

          <div className="card-body">
            <h3>
              <pre style={{ color: "#9f55ff" }}>
                Drops
                <Link
                  to="/auctionDrops"
                  style={{ float: "right", color: " #9f55ff" }}
                >
                  View All{" "}
                </Link>
              </pre>
            </h3>
            <hr></hr>
            <div className="form-group">
              {open ? (
                <div align="center" className="text-center">
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ color: " #9f55ff" }}
                  ></Spinner>
                  <span style={{ color: " #9f55ff" }} className="sr-only">
                    Loading...
                  </span>
                </div>
              ) : tokenList.length === 0 ? (
                <Typography
                  variant="h6"
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                >
                  <strong style={{ color: "#9f55ff" }}>
                    Nothing to Display{" "}
                  </strong>
                </Typography>
              ) : (
                // <Grid
                //   container
                //   spacing={2}
                //   direction="row"
                //   justify="flex-start"
                //   // alignItems="flex-start"
                // >
                <DropsCardContainer>
                  {tokenList.map((i, index) => (
                      new Date() > new Date(i.auctionStartsAt) &&
                          new Date() < new Date(i.auctionEndsAt) ? <Link to={"/auctionDrops/DropCubes/Nfts/" + i._id + "/" + i.tokenIds[0]} key={index}>
                              <AuctionCard inde={index} item={i} />
                          </Link> : null
                  ))}
                </DropsCardContainer>
                // </Grid>
              )}
            </div>
          </div>
          {/* </Container> */}
        </div>
      </div>
    </>
  );
}

export default Drops;
