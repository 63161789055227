// const IPFS = require('ipfs-api');
// const ipfs = IPFS({ host: 'ipfs.infura.io', port: '5001', protocol: 'https' });
// export default ipfs

// const IPFS = require("ipfs-http-client");
import IPFS from 'ipfs-http-client';
const INFURAID = "2FdDlBisXqYxHwTkwqXkXU94aSa";
const APIKEY = "3052c6aeee5df1d0c434d5d6411df059";
const auth = "Basic " + Buffer.from(INFURAID + ":" + APIKEY).toString("base64");
const ipfs = IPFS({
  host: "ipfs.infura.io",
  port: "5001",
  protocol: "https",
  headers: { authorization: auth },
});
//const ipfs = IPFS({ host: "ipfs.infura.io", port: "8081", protocol: "http" });
export default ipfs;

