import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { SnackbarProvider } from 'notistack';
import React, { useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import AdminDashboard from "./Dashboard/AdminDashboard";
import CubeNFTs from "./Users/Drops/CubeNFTs";
import UserDashboard from "./Dashboard/UserDashboard";
import AuctionDrops from "./Users/AuctionDrops";
import DropCubes from "./Users/Drops/DropCubes";

import EmailVerification from "./Users/EmailVerification";
import ForgotPassword from "./Users/ForgotPassword/ForgotPassword";
import HomeScreen from "./Users/HomeScreen";
import LoginScreen from "./Users/Login/LoginScreen";
import MarketPlace from "./Users/MarketPlace";
import PrivacyPolicy from "./Users/PrivacyPolicy";
import TermsAndConditions from "./Users/TermsAndConditions";
import SaleCubeNFTs from "./Users/MarketPlace/SaleCubeNFT";
import AuctionCubeNFTs from "./Users/MarketPlace/AuctionCubeNFT";

function App() {
    let isLoggedIn;
    let jwtDecoded;
    let checkLoginStatus = () => {
        // Cookies.remove("Authorization");
        let jwt = Cookies.get("Authorization");
        if (jwt) {
            //console.log(jwtDecode(jwt));
            // setjwtDecoded(jwtDecode(jwt));
            jwtDecoded = jwtDecode(jwt);
            console.log("jwtDecoded login", jwtDecoded);
            isLoggedIn = true;
            //setIsLoggedIn(isLoggedIn);
        } else {
            // setIsLoggedIn(false);
            isLoggedIn = false;
        }
    };

    useEffect(() => {

        checkLoginStatus();// eslint-disable-next-line
    }, []);

    const PrivateRoute = ({ path, ...rest }) => {
        // checkLoginStatus();
        if (jwtDecoded && isLoggedIn) {
            if (jwtDecoded.role === "admin") {
                console.log("tesitngngng" + isLoggedIn)
                return (
                    <Route
                        {...rest}
                        render={(props) =>
                            isLoggedIn ? (
                                <AdminDashboard {...props} jwtDecoded={jwtDecoded} />
                            ) : (
                                <Redirect to="/" />
                            )
                        }
                    />
                );
            }
            else if (jwtDecoded.role === "user") {
                return (
                    <Route
                        {...rest}
                        render={(props) =>
                            isLoggedIn ? (
                                <UserDashboard {...props} jwtDecoded={jwtDecoded} />
                            ) : (
                                <Redirect to="/" />
                            )
                        }
                    />
                );
            }
            // else if (jwtDecoded.roles === "exporter") {
            //   return (
            //     <Route
            //       {...rest}
            //       render={(props) =>
            //         isLoggedIn ? (
            //           <ExporterDashboard {...props} jwtDecoded={jwtDecoded} />
            //         ) : (
            //             <Redirect to="/login" />
            //           )
            //       }
            //     />
            //   );
            // }
        }
        else {
            return <Redirect to="/" />;
        }
    };

    const LoginRegisterRedirectCheck = ({ path, ...rest }) => {
        checkLoginStatus();
        if (jwtDecoded && isLoggedIn && jwtDecoded.role === "admin") {
            console.log("testLoginFunction")
            return <Redirect to="/dashboard" />;
        } else if (path === "/admin-login") {
            return <Route component={LoginScreen} />;
        } else if (path === "/marketPlace") {
            return <Route component={MarketPlace} />;
        } else if (path === "/auctionDrops") {
            return <Route component={AuctionDrops} />;
        } else if (path === "/auctionDrops/DropCubes/:dropId") {
            return <Route exact path="/auctionDrops/DropCubes/:dropId" render={(routeProps) => <DropCubes {...routeProps} />} />
        } else if (path === "/auctionDrops/DropCubes/Nfts/:dropId/:cubeId") {
            return <Route exact path="/auctionDrops/DropCubes/Nfts/:dropId/:cubeId" component={CubeNFTs} />;
        } else if (path === "/marketPlace/Cubes/Nfts/notdrop/:expiresAt/:cubeId/:auctionId") {
            return <Route exact path="/marketPlace/Cubes/Nfts/notdrop/:expiresAt/:cubeId/:auctionId" component={SaleCubeNFTs} />;
        } else if (path === "/marketPlace/Cubes/Nfts/userauction/:cubeId/:auctionId") {
            return <Route exact path="/marketPlace/Cubes/Nfts/userauction/:cubeId/:auctionId" component={AuctionCubeNFTs} />;
        } else {
            return <Route component={HomeScreen} />;
        }
    };

    return (
        <SnackbarProvider maxSnack={3}>
            <BrowserRouter>
                <Switch>
                    <LoginRegisterRedirectCheck exact path="/" />
                    {/* <LoginRegisterRedirectCheck exact path="/login" /> */}
                    <LoginRegisterRedirectCheck exact path="/register" />
                    <LoginRegisterRedirectCheck exact path="/marketPlace" />
                    <LoginRegisterRedirectCheck exact path="/admin-login" />
                    <LoginRegisterRedirectCheck exact path="/auctionDrops" />
                    <LoginRegisterRedirectCheck exact path="/auctionDrops/DropCubes/:dropId" component={DropCubes} />
                    <LoginRegisterRedirectCheck exact path="/auctionDrops/DropCubes/Nfts/:dropId/:cubeId" component={CubeNFTs} />
                    <LoginRegisterRedirectCheck exact path="/marketPlace/Cubes/Nfts/notdrop/:expiresAt/:cubeId/:auctionId" component={SaleCubeNFTs} />
                    <LoginRegisterRedirectCheck exact path="/marketPlace/Cubes/Nfts/userauction/:cubeId/:auctionId" component={AuctionCubeNFTs} />

                    <Route path="/forgotpassword" component={ForgotPassword} />
                    <Route
                        path="/emailverification/:email/:token"
                        render={(routeProps) => <EmailVerification {...routeProps} />}
                    />
                    {/* <Route exact path="/admin-login"component={LoginScreen} /> */}
                    <Route path="/termsandconditions" component={TermsAndConditions} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    {/* {jwtDecoded.roles === "user" ? (
            <Route path="/dasboard" component={UserDashboard} />
          ) : (null)} */}

                    <PrivateRoute path="/dashboard" />
                </Switch>
            </BrowserRouter>
        </SnackbarProvider>
    );
}

export default App;
