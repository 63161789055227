// export const CreateNftAddress = '0x9B2D957D6Da0477be3d7070F30F7524E69976A0d';
// export const CreateCubeAddress = '0x2fC35aEDBC3d47837F24A746D67782fe9c87C0E8';
// export const AuctionAddress = "0x21A6256e5BB6Fe4f83F76A9701402ed555bDCFac";
// export const WethAddress = "0x0a180a76e4466bf68a7f86fb029bed3cccfaaac5";
// export const MarketPlaceAddress = "0x89c91582cD0098449E066eB5A31fC10986eACBB1";


// export const CreateNftAddress = "0xeEed8dE33C654fA6b0D54125bF8C3a699E98E543";
// export const CreateCubeAddress = "0x2D6eDc8E035Bd1c9E9fD105B5c4ef4e4B716ea20";
// export const AuctionAddress = "0xc868825AFb75c228867fcFc93CcD2e8c08B7A80E";
// export const WethAddress = "0x9921CDd0d8819F22b4B53B1cb700cF0d7f8B7149";
// export const MarketPlaceAddress = "0xd072aa6C757F985BD42B9e6F692Fe38439720eAA";

// 80001 mumbai 
// export const CreateNftAddress= "0x80bdd6ebFF4833B56A15a405C0d9B620aD9d9951";
// export const CreateCubeAddress= "0xF9b16a46410A501De19E96D65BE910A852a21633";
// export const AuctionAddress= "0x8B29fF1890E366E7c0FF5E7700502E103D6F14C5";
// export const WethAddress= "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270";
// export const MarketPlaceAddress= "0xd72E077182C41d2A1482b02370eb0c04db254465";

// 137 polygon
export const CreateNftAddress= "0x80bdd6ebFF4833B56A15a405C0d9B620aD9d9951";
export const CreateCubeAddress= "0xF9b16a46410A501De19E96D65BE910A852a21633";
export const AuctionAddress= "0x8B29fF1890E366E7c0FF5E7700502E103D6F14C5";
export const WethAddress= "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270";
export const MarketPlaceAddress= "0xd72E077182C41d2A1482b02370eb0c04db254465";