import React from "react";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import {
  AuctionCardWrapper,
  AuctionCardDescriptionWrapper,
  AuctionCardRow,
  CardDescriptionTitle,
  CardDescription,
  AuctionInfoText,
  AuctionCardStyled,
} from "./Card.styles";
import {useState} from 'react';
import Typography from "@material-ui/core/Typography";
import Countdown from "react-countdown";

import rbt from "../../assets/img/rbt.png";

const AuctionCard = ({ type, index, item }) => {
    const [isHovering, setIsHovering] = useState(false);
  const { image } = item;
    const handleMouseOver = () => {
        setIsHovering(true);
    };
    const handleMouseOut = () => {
        setIsHovering(false);
    };
  return (
    <AuctionCardWrapper index={index}>
        {   new Date() > new Date(item.auctionStartsAt) &&
        new Date() < new Date(item.auctionEndsAt) ? (
        <AuctionCardStyled type={type} index={index}>
            <CardMedia
                component="img"
                src={item.image}
                alt="NFT Image"
                style={{
                    width: "180px",
                    height: "160px",
                    marginLeft: "35px",
                    marginTop: "3px",
                }}
            ></CardMedia>
            <CardContent>
                <AuctionCardDescriptionWrapper>
                    <AuctionCardRow>
                        <CardDescriptionTitle>Drop Description:</CardDescriptionTitle>
                        <CardDescription   onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>{((item.description).length > 10 && !isHovering ) ? (((item.description).substring(0,7)) + '...') : item.description}</CardDescription>
                    </AuctionCardRow>
                    <AuctionCardRow>
                        <CardDescriptionTitle>Collection:</CardDescriptionTitle>
                        <CardDescription>{item.bidDelta / 10 ** 18} WMATIC</CardDescription>
                    </AuctionCardRow>
                    <AuctionCardRow>
                        <CardDescriptionTitle>Minimum Bid:</CardDescriptionTitle>
                        <CardDescription>{item.minimumBid / 10 ** 18} WMATIC</CardDescription>
                    </AuctionCardRow>
                </AuctionCardDescriptionWrapper>
                <Typography
                    variant="h6"
                    gutterBottom
                    color="textSecondary"
                    className="text-center"
                >
                    {new Date() < new Date(item.auctionStartsAt) ? (
                        <div style={{color: "#00FF00"}}>
                            <Typography variant="body2" color="textSecondary" component="p">
                                <strong style={{color: "#fff"}}>Auction Starts At:</strong>
                            </Typography>
                            {/* {console.log("Date(i.AuctionStartsAt)", Date(i.AuctionStartsAt))} */}
                            <Countdown
                                daysInHours
                                date={new Date(item.auctionStartsAt)}
                            ></Countdown>
                        </div>
                    ) : new Date() > new Date(item.auctionStartsAt) &&
                    new Date() < new Date(item.auctionEndsAt) ? (
                        <div style={{color: " #9f55ff"}}>
                            {/* {console.log("Date(i.AuctionStartsAt)", Date(i.AuctionEndsAt.toLoca))} */}
                            <Typography variant="body2" color="textSecondary" component="p">
                                <strong style={{color: "#fff"}}>Auction Ends At:</strong>
                            </Typography>
                            <Countdown
                                style={{color: "#fff"}}
                                daysInHours
                                date={new Date(item.auctionEndsAt)}
                            ></Countdown>
                        </div>
                    ) : (
                        <Typography
                            variant="body2"
                            style={{color: " #9f55ff"}}
                            component="p"
                        >
                            <strong style={{color: "#fff"}}>Auction Ended</strong>
                        </Typography>
                    )}
                </Typography>
            </CardContent>
        </AuctionCardStyled>) : (<Typography
            style={{display: "none"}}
            component="p">
        </Typography>)}
    </AuctionCardWrapper>
            );
};

export default AuctionCard;
